module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hectiq.AI","short_name":"Hectiq.AI","description":"Partenaire de solutions d’intelligence artificielle","lang":"fr","display":"standalone","start_url":"/","background_color":"#fff","theme_color":"#354178","icon":"src/images/favicon.png","localize":[{"start_url":"/en/","lang":"en","description":"Artificial intelligence solutions provider","name":"Hectiq.AI","short_name":"Hectiq.AI"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7b74d2738cb66a662fb69e1faca11ff"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5NV01NV77G"],"pluginConfig":{"head":true,"respectDNT":true,"optinKey":"cookies-consent"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"disableBgImageOnAlpha":true,"backgroundColor":"transparent","linkImagesToOriginal":false,"tracedSVG":false,"loading":"eager"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"fr","generateDefaultLanguagePage":true,"siteUrl":"https://www.hectiq.ai","trailingSlash":"always","redirect":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":false},{"matchPath":"/:lang?/case-study","getLanguageFromPath":false},{"matchPath":"/:lang?/careers","getLanguageFromPath":false},{"matchPath":"/:lang?/contact","getLanguageFromPath":false},{"matchPath":"/:lang?/about","getLanguageFromPath":false},{"matchPath":"/:lang?/case-study/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/careers/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
